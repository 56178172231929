import table from "@/utils/table";
import Constants from "@/utils/Constants";
const math = require("mathjs");
import {getVueInstance} from "@/main"
export  default  class CaloTyuauterieHelper{
    private static readonly vue = getVueInstance();
    public  static  computeOperationPoints(operation,rowData) {
        const formula = this.vue.$store.state.selectedBopModel.functionUnitPoints;
        const regex = /{[\w.]*}/gm;
        let m;
        let formulaElements: Array<any> = [];
        while ((m = regex.exec(formula)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            for (let i = 0; i < m.length; i++) {
                const match = m[i];
                //   console.log(`Found match, group ${groupIndex}: ${match}`);
                const path: string = match.replace(/{|}/gm, "");
                //replacing the {key} with the corresponding value
                let value: any = "";
                if (!path.includes("globalCoefficient")) {
                    let params = {source: rowData, path};
                    if (path.includes("operation")) {
                        params.source = operation;
                        params.path = path.substr(path.indexOf('.') + 1);
                    } else if (path.includes("quantity")) {
                        params.source = operation;
                    }
                    value = table.findByPath(params.source, params.path);
                } else {
                    const lastIndexOfDot = path.indexOf(".");
                    const key = path.substring(lastIndexOfDot + 1);
                    /**
                     * if the coefficient is not yet selectionned no need to continue further calculus and parsing
                     */
                    if (this.vue.$store.state.selectedGlobalCoefficients[key]) {
                        value = this.vue.$store.state.selectedGlobalCoefficients[key].value;
                    } else {
                        value = null;
                    }
                }
                // console.log(`OPERATION: ${this.rowData.operation.code}`);
                //  console.log(`path: ${path} value:${value}`);

                if (!value || value === Constants.DEFAULT_VALUE_EMPTY_OPTIONS) {
                    operation.totalPoints = "0";
                    return;
                }
                formulaElements.push({
                    key: match,
                    value,
                });
            }
        }
        let equation = formula;
        // console.log(formulaElements);
        formulaElements.forEach((el: any) => {
            equation = equation.replace(el.key, el.value);
        });
        // console.log(equation);
        let result = math.evaluate(equation);
        result = Math.round((result + Number.EPSILON) * 100) / 100;
        // console.log(result);
        operation.totalPoints = result;
    }

}