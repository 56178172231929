import { render, staticRenderFns } from "./BopDropDownField.vue?vue&type=template&id=67a06e12&scoped=true&"
import script from "./BopDropDownField.vue?vue&type=script&lang=ts&"
export * from "./BopDropDownField.vue?vue&type=script&lang=ts&"
import style0 from "./BopDropDownField.vue?vue&type=style&index=0&id=67a06e12&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67a06e12",
  null
  
)

export default component.exports