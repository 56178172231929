






import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import Constants from "@/utils/Constants";
const math = require("mathjs");
const MONTAGE_OPERATION_FAMILY_KEY = "montage";
const DEMONTAGE_OPERATION_FAMILY_KEY = "demontage";
const NATURE_TACHE_COEFFICIENT_KEY = "K2";
type globalCoefficient = {
  coefficientId:string,
  coefficientOptionId:string,
  operationFamilyCoefficientOptionList: {
    operationFamilyId: string,
    operationFamilyKey:string,
    secondValue: number,
    value: number
  }[],
  secondValue: number,
  value: number
}
@Component({
  components: {},
})
export default class DisabledDynamicInputField extends BaseField {
  private computedVal: string = "";
  private get fieldKey(): string {
    return this.props.rowField.fieldKey;
  }
  private get selectedGlobalCoefficients() {
    return this.$store.state.selectedGlobalCoefficients;
  }
  private formula: string = "";
  private get localFormula() {
    return this.props.rowField.formula;
  }
  // private set formula(formula) {
  //   this.props.rowField.formula = formula;
  // }

  private get property() {
    return this.props.rowField.property;
  }
  private get rowData() {
    return this.props.rowData;
  }

  private isSelectFormula = false;

  private get montageDemontageKey() {
    return this.props.rowField.montageDemontageKey;
  }
  private async compute() {
    // try {
    if (this.isSelectFormula) {
      this.computedVal = table.findByPath(this.rowData, this.formula);
      if (this.property) {
        this.rawData = this.computedVal;
      }
      //   console.log(this.computedVal);
      //  console.log(this.rowData);
      //    console.log(this.formula);
    } else {
      // console.group("lets start computing ....");
      // console.log(this.formula);

      const regex = /{[\w.]*}/gm;
      let m;
      let formulaElements: Array<any> = [];
      while ((m = regex.exec(this.formula)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        for (let i = 0; i < m.length; i++) {
          const match = m[i];
          //   console.log(`Found match, group ${groupIndex}: ${match}`);
          const path: string = match.replace(/{|}/gm, "");
          //replacing the {key} with the corresponding value
          let value: any = "";
          if (path.indexOf("globalCoefficient") === -1) {
            value = table.findByPath(this.props.rowData, path);
          } else {
            const lastIndexOfDot = path.indexOf(".");
            const key = path.substring(lastIndexOfDot + 1);
            /**
             * if the coefficient is not yet selectionned no need to continue further calculus and parsing
             */
            if (this.$store.state.selectedGlobalCoefficients[key]) {
              // in case its nature des tache (k2) in calo appareil we have a value for demontage and second value for montage
              if (this.montageDemontageKey && key === NATURE_TACHE_COEFFICIENT_KEY) {
                const selectedCoeff:globalCoefficient = this.$store.state.selectedGlobalCoefficients[key];
                // console.log('===== selectedCoeff=========');
                // console.log(selectedCoeff);
                const currentOperationFamilyKey = this.rowData.operation.operationFamilyKey;
                // console.log('======currentOperationFamilyKey=======');
                // console.log(currentOperationFamilyKey);
                const cf = selectedCoeff.operationFamilyCoefficientOptionList.find(el => el.operationFamilyKey=== currentOperationFamilyKey);
                // console.log('=======coeffValue=====');
                // console.log(cf);
                const coeffValue =  cf ?? {
                  operationFamilyKey : currentOperationFamilyKey,
                  value: 0,
                  secondValue: 0,
                  operationFamilyId : this.rowData.operation.operationFamilyId
                };
                value = (this.montageDemontageKey === DEMONTAGE_OPERATION_FAMILY_KEY ? coeffValue.value: coeffValue.secondValue);
                this.rowData.testDebug = value;
              } else {
                value = this.$store.state.selectedGlobalCoefficients[key].value;
              }
            } else {
              value = null;
            }
          }
          // console.log(`OPERATION: ${this.rowData?.operation?.code}`);
           // console.log(`path: ${path} value:${value}`);

          if (value === undefined || value === null || value === Constants.DEFAULT_VALUE_EMPTY_OPTIONS) {
            this.computedVal = "0";
            this.rawData = 0;
            return;
          }
          formulaElements.push({
            key: match,
            value,
          });
        }
      }
      let equation = this.formula;
      // console.log(formulaElements);
      formulaElements.forEach((el: any) => {
        equation = equation.replace(el.key, el.value);
      });
      // console.log(equation);
      let result = math.evaluate(equation);
      result = Math.round((result + Number.EPSILON) * 100) / 100;
       // console.log(result);
      this.computedVal = result;
      this.rawData = this.computedVal;
      // console.groupEnd();
    }
    // } catch {}
  }
  private loadFormula() {
    if (this.localFormula) {
      this.formula = this.localFormula;
    } else {
      switch (this.fieldKey) {
        case "totalPointsFormula":
          // console.group("load totalPointsFormula ");
          // console.log(this.props.rowData.operation.code);
          // console.log(this.formula);
          // console.log(
          //   this.props.rowData.operation.operationFamilyFunctionUnitPoints
          // );
          // console.groupEnd();
          // this.formula = this.$store.state.operationFunctions[this.rowData.operation.id]?.functionUnitPoints;
          this.formula = this.props.rowData.operation.operationFamilyFunctionUnitPoints;
          // (<any>this.$parent).reload();
          break;
        case "unitValueFormula":
          // console.group("load unitValueFormula");
          // console.log(this.props.rowData.operation.code);
          // console.log(this.formula);
          // console.log(
          //   this.props.rowData.operation
          //     .operationFamilyFunctionOperationUnitPoints
          // );
          // console.groupEnd();
          this.formula = this.$store.state.operationFunctions[
            this.rowData.operation.id
          ]?.functionOperationUnitPoints;
          // (<any>this.$parent).reload();
          break;
      }
    }
    this.isSelectFormula = !/[*+/-]/.test(this.formula);
  }
  /***
   * ######
   * Hooks
   * ######
   */
  private async beforeMount() {
    // console.group("mounted");
    // // console.log(this.formula);
    // console.log(this.watchedProperties);
    // console.groupEnd();
    this.loadFormula();

    // if (this.watchedProperties.operationId) {
    // await this.$nextTick();
    this.compute();
    (<any>this.$parent).reload();
    // }
  }
  @Watch("rowData", { deep: true })
  private async onRowDataChange() {

    this.loadFormula();
    // console.group("watcher");
    await this.$nextTick();
    // console.log("lets compute ?");
    this.compute();

    // console.groupEnd();
  }
  @Watch("selectedGlobalCoefficients", { deep: true })
  private async onSelectedGlobalCoefficients() {
    this.compute();
  }
}
