



























import {Component, Watch} from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import {BopModelEnum} from "@/utils/Enums";
import Constants from "@/utils/Constants";
import CaloTyuauterieHelper from "@/utils/CaloTyuauterieHelper";

const DISABLING_DIAMETRE_OPERATION_FAMILIES = [
  "nombreDeDecoupes",
  "semiDemontables",
  "piecesDeForme",
  "demontables",
];
const DISABLING_LONGUEUR_OPERATION_FAMILIES = [
  "nombreDeDecoupes",
  "fondsBombesOuConiques",
  "semiDemontables",
  "piecesDeForme",
  "demontables",
];
@Component({
  components: {},
})
export default class CoefficientEditField extends BaseField {
  private isBeingEdited: Boolean = false;
  private previous: any = "";

  private width: number = 0;

  // private IDSAPRegex
  get isCalorifugeageAppareil(): boolean {
    return (
        this.$store.state.selectedBopModel.key ===
        BopModelEnum.CalorifugageAppareil
    );
  }

  private get displayedValue(): string {
    return this.rawData === Constants.DEFAULT_VALUE_EMPTY_OPTIONS
        ? ""
        : this.rawData;
  }

  private set displayedValue(newValue) {
    this.rawData = newValue;
  }

  private get specialClass(): string {
    if (
        this.props.rowField.property === "name" &&
        this.props.rowField.dataClass === "bop"
    ) {
      return "bopName";
    } else {
      return "";
    }
  }

  private get operationFamilyKey() {
    return this.props.rowData.operation?.operationFamilyKey;
  }
  private get operationId() {
    return this.props.rowData.operation?.id;
  }

  private get coefficientId(): string {
    return this.props.rowField.coefficient.id;
  }

  private get coefficientKey(): string {
    return this.props.rowField.coefficient.key;
  }

  private get dataContainer(): string {
    return this.props.rowField.dataContainer;
  }
  private get isCalorifugeageTyuauterie(): boolean {
    return this.$store.state.selectedBopModel?.key === BopModelEnum.CalorifugageTuyauterie;
  }
  public edit(): void {
    if (this.computedCanBeEdited) {
      const height = (<HTMLElement>this.$refs.container).offsetHeight;
      const width = (<HTMLElement>this.$refs.container).offsetHeight;
      const clientLeft = (<HTMLElement>this.$refs.container).clientLeft;
      const clientTop = (<HTMLElement>this.$refs.container).clientTop;
      const paddingLeft = parseInt((<HTMLElement>this.$refs.container).style.paddingLeft.replace("px", ""));
      const paddingRight = parseInt((<HTMLElement>this.$refs.container).style.paddingRight.replace("px", ""));
        (<HTMLElement>this.$refs.input).style.width = width - paddingLeft - paddingRight - (clientLeft * 2) - 2 + "px";
        (<HTMLElement>this.$refs.input).style.height = height - (clientTop * 2) - 2 + "px";;
      this.isBeingEdited = !this.isBeingEdited;
      if (this.isBeingEdited) {
        this.previous = this.rawData;
        this.$nextTick(() => {
          (<HTMLInputElement>this.$refs.input).select();
        });
      } else {
        this.close();
      }
    }
  }
  public close() {
    this.isBeingEdited = false;
    this.$nextTick().then(() => {
      if(this.isCalorifugeageTyuauterie){
        this.props.rowData.isSelectedGroupCoefficientsProcessed = false;
      }
      table.setByPath(this.props.rowData, this.dataContainer, this.displayedValue);
    });
    (<any>this.$parent).reload();
  }

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }

  private created() {
    if (this.rawData) {
      table.setByPath(this.props.rowData, this.dataContainer, this.rawData);
    }
    if (this.isCalorifugeageAppareil) this.onOperationFamilyKeyChange();
  }

  private mounted() {
    (<any>this.$parent).reload();
  }

  @Watch("operationId")
  private onOperationIdChange() {
    this.onOperationFamilyKeyChange();
  }

  @Watch("operationFamilyKey")
  private onOperationFamilyKeyChange() {
    if (!this.isCalorifugeageAppareil) return;
    // if (this.rawData === Constants.DEFAULT_VALUE_EMPTY_OPTIONS) {
    //   this.canBeEdited = false;
    //   return;
    // }
    if (this.coefficientKey) {
      if (
          this.coefficientKey === "diametre" &&
          DISABLING_DIAMETRE_OPERATION_FAMILIES.includes(this.operationFamilyKey)
      ) {
        this.canBeEdited = false;
        this.rawData = Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
      } else if (
          this.coefficientKey === "longueur" &&
          DISABLING_LONGUEUR_OPERATION_FAMILIES.includes(this.operationFamilyKey)
      ) {
        this.rawData = Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
        this.canBeEdited = false;
      } else {
        this.canBeEdited = true;
        // this.rawData = 0;
      }
    }
  }

  // @Watch("rawData")
  // onRawDataChange() {
  //   table.setByPath(this.props.rowData, this.dataContainer, this.rawData);
  //   (<any>this.$parent).reload();
  // }
}
