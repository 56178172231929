





















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import lodash from "lodash";
import table from "@/utils/table";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableRowHeader from "vuetable-2/src/components/VuetableRowHeader.vue";
import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue";

import DetailRow from "@/components/Shared/Table/DetailRow.vue";
import RowFilter from "@/components/Shared/Table/RowFilter.vue";

import EditField from "@/components/Shared/Table/EditField.vue";
import CoefficientEditField from "@/components/Shared/Table/CoefficientEditField.vue";
import DropdownField from "@/components/Shared/Table/DropdownField.vue";
import BopDropDown from "@/components/Shared/Table/BopDropDownField.vue";
import BopOperationDropDown from "@/components/Shared/Table/BopOperationDropDownField.vue";
import BopCoefficientDropDown from "@/components/Shared/Table/BopCoefficientDropDownField.vue";
import BopOperationFamilyDropdown from "@/components/Shared/Table/BopOperationFamilyDropdownField.vue";
import DisabledDynamicInputField from "@/components/Shared/Table/DisabledDynamicInputField.vue";
import BopQuantityField from "@/components/Shared/Table/BopQuantityField.vue";
import { BIconTrashFill } from "bootstrap-vue";
import api from "@/utils/api";
import { Guid } from "guid-typescript";

Vue.component("my-detail-row", DetailRow);
Vue.component("vuetable-field-checkbox", VuetableFieldCheckbox);

type SortOrder = {
  field: string;
  direction: string;
  sortField: string;
};

@Component({
  components: {
    VuetablePagination,
    VuetableRowHeader,
    RowFilter,
    EditField,
    DropdownField,
    BIconTrashFill,
    DisabledDynamicInputField,
    BopDropDown,
    BopOperationDropDown,
    BopCoefficientDropDown,
    CoefficientEditField,
    BopQuantityField,
    BopOperationFamilyDropdown,
    VueBootstrapTypeahead: () => import("vue-bootstrap-typeahead"),

    // UnsavedModal
  },
})
export default class BopSetupTable extends Vue {
  @Prop({ required: true }) public columns!: Array<Object>;
  @Prop({ required: true }) public trackBy!: string;
  @Prop() public addLabel!: String;
  @Prop() public modifiedData!: Array<Object>;
  @Prop() public operations!: Array<Object>;
  @Prop() public autoCompleteData!: Array<any>;
  @Prop() public autoCompleteSerializer!: any;
  @Prop() public isReadOnly!: boolean;
  @Prop({default: () => false}) public isAddDisabled!: boolean;
  private selectedRows: Array<Object> = [];
  public myDetailRow: object = DetailRow;
  public updatedRows: Array<Object> = [];
  public initialRows: Array<Object> = [];
  private sortOrder: Array<Object> = [];
  private perPage: number = 20;
  private hasBeenModified: Boolean = false;
  private pagination: any = undefined;
  private input: string = "";
  private totalPoints: number = 0;
  private selectedOperation: any = null;
  //methods
  private CalculatetotalPoints() {
    let total = 0;
    // await this.$nextTick();
    for (let i = 0; i < this.initialRows.length; i++) {
      const el: any = this.initialRows[i];

      if (!isNaN(el.totalPoints)) total += el.totalPoints;
    }
    this.totalPoints = Math.round((total + Number.EPSILON) * 100) / 100;
  }
  onHit(element) {
    this.selectedOperation = element;
  }
  private addTheFoundOperation() {
    this.$emit("autocomplete-add-data", this.selectedOperation);
  }
  public save(): void {
    // console.log("Saving");
    this.$emit("save-data");
  }

  public add(): void {
    if(this.isAddDisabled)return;
    this.$store.commit("changeHasBeenModified", true);
    this.$emit("add-data");
  }

  public refresh(): void {
    // console.log("Refreshing the table");
    this.$store.commit("changeIsFirstCheckBoxTableLoad", true);
    this.$store.commit("changeHasBeenModified", false);
    (<any>this.$refs.vuetable).refresh();
  }

  private onChangePage(page: any): void {
    (<any>this.$refs.vuetable).changePage(page);
  }
  private getrawData(obj: any, path: string): any {
    return table.findByPath(obj, path);
  }

  private async duplicate(params: any) {
    const rowData = params.rowData;
    const rowIndex = params.rowIndex + 1;

    const restOfArray = this.updatedRows.splice(rowIndex);
    let newData = lodash.cloneDeep(rowData);
    await this.$nextTick();

    table.setByPath(newData, this.trackBy, Guid.createEmpty().toString());
    this.updatedRows.push(newData);
    this.updatedRows = this.updatedRows.concat(restOfArray);
    this.$emit("duplicate-data", newData);
  }
  private remove(props: any) {
    this.$bvModal
      .msgBoxConfirm(this.$t("bopConfirmDelteOperation.message").toString(), {
        title: this.$t("bopConfirmDelteOperation.title").toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: this.$t("bopConfirmDelteOperation.yes").toString(),
        cancelTitle: this.$t("bopConfirmDelteOperation.no").toString(),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
        dialogClass: "modal-app",
      })
      .then((value) => {
        if (value) {
          this.$emit("remove-data", {
            rowData: props.rowData,
            rowIndex: props.rowIndex,
          });
        }
      });
  }

  public handleRemove(props: any) {
    this.$emit("handleRemove-data", {
      rowData: props.rowData,
      rowIndex: props.rowIndex,
    });
  }

  public removeRow(rowIndex) {
    this.updatedRows.splice(rowIndex, 1);
  }
  /**
   * modify
   * Triggered when a cell has been modified
   */
  public modify(props: any) {
    const path = (<any>this.$refs.vuetable).trackBy;
    if (!this.isReadOnly) this.$store.commit("changeHasBeenModified", true);
    const propToSearch = table.findByPath(props, path);

    let index = this.modifiedData.findIndex(function(item, index) {
      return propToSearch === table.findByPath(item, path);
    });
    if (index > -1) {
      this.modifiedData.splice(index, 1);
    }
    this.modifiedData.push(props);
  }

  /**
   * Sort rows based on sortOrder
   * Update the pagination
   *
   * @param {array} sortOrder sortOrder that has been changed
   * @param {any} pagination global sorting order
   * @return {object} sorted rows, pagination
   */
  private dataManager(sortOrder: Array<SortOrder>) {
    if (this.updatedRows.length < 1) {
      return {
        data: [],
      };
    }
    return {
      data: this.updatedRows.map(r=> r)
    };
  }
  public reload() {
    (<any>this.$refs.disabledFields).compute();
  }
  //hooks
  private created() {
    this.updatedRows = this.operations;
    this.initialRows = this.operations;

    // console.log(this.totalPoints);
  }
  private mounted() {
    this.$nextTick().then(() => {
      this.CalculatetotalPoints();
      
      setTimeout(() => {
        this.$store.commit("changeHasBeenModified", false);
        this.$emit("table-mounted");
      }, 200);
    });
  }

  //Watchers
  @Watch("totalPoints")
  onTotalPointsChange() {
    this.$emit("totalPointsChanged", this.totalPoints);
  }
  @Watch("input")
  onInputChange(newValue: string, oldvalue: string) {
    const filter = newValue.toUpperCase();
    this.updatedRows = [];
    this.initialRows.forEach((row) => {
      for (let value of Object.values(row)) {
        if (
          value &&
          typeof value == "string" &&
          value
            .toString()
            .toUpperCase()
            .indexOf(filter) > -1
        ) {
          this.updatedRows.push(row);
          break;
        }
      }
    });
    this.refresh();
  }

  @Watch("updatedRows", { deep: true })
  onPropertyChanged(value: Array<Object>, oldValue: Array<Object>) {
    // console.log('Im updated :D')
    // this.CalculatetotalPoints();
    this.refresh();
  }
  @Watch("modifiedData", { deep: true })
  onModifiedDataChange(value: Array<Object>, oldValue: Array<Object>) {
    // console.log("Im updated :D");
    this.CalculatetotalPoints();
    // this.refresh();
  }
}
