



























import {Component, Watch} from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import {BopModelEnum} from "@/utils/Enums";
import Constants from "@/utils/Constants";
import CaloTyuauterieHelper from "@/utils/CaloTyuauterieHelper";
import OperationExceptionService from "@/services/OperationExceptionService";
import {Guid} from "guid-typescript";

const math = require("mathjs");

@Component({
  components: {},
})
export default class BopQuantityField extends BaseField {
  // @Prop({ required: true }) public props!: any;
  private isBeingEdited: Boolean = false;
  private previous: any = "";

  private width: number = 0;

  get isCalorifugeageAppareil(): boolean {
    return this.$store.state.selectedBopModel &&this.$store.state.selectedBopModel.key === BopModelEnum.CalorifugageAppareil;
  }
  private  get hideUnit():boolean{
    return this.props.rowField.hideUnit ?? false;
  }
  private get unit(): string {
    const unit = this.props.rowField.operationUnit ?? this.props.rowData?.operation?.operationMesureUnitValue;
    return unit ?? "U";
  }

  private get specialClass(): string {
    if (
        this.props.rowField.property === "name" &&
        this.props.rowField.dataClass === "bop"
    ) {
      return "bopName";
    } else {
      return "";
    }
  }

  private get isCalorifugeageTyuauterie(): boolean {
    return this.$store.state.selectedBopModel && this.$store.state.selectedBopModel.key === BopModelEnum.CalorifugageTuyauterie;
  }

  private get computedClass() {
    let res = "quantity ";
    if (!this.isReadOnly()) res += "field-pointer";
    else {
      res += " disabled";
    }
    if (this.rawData && this.rawData.length > 100) res += " larger";

    return res;
  }
  private get selectedGroupCoefficient(){
    return this.isCalorifugeageTyuauterie ? this.props.rowData.coefficientSelectedValues : null;
  }
  private get currentOperation(){
    return this.props.rowData.operations[this.props.rowField.operationKey];
  }
  private isReadOnly(){
    const isReadOnly = !(this.computedCanBeEdited && (!this.isCalorifugeageTyuauterie || !(this.props.rowData.operations[this.props.rowField.operationKey].isReadOnly ?? false)));
    return isReadOnly;
  }
  private  get isDisabled():boolean{
    return  this.props.rowField.disabled;
  }
  private async getExceptionOperationCodes(){
    if(!this.isCalorifugeageTyuauterie)return;
    const epaisseurCal = this.selectedGroupCoefficient['EpaisseurColorifuge'].value
    const diametre = this.selectedGroupCoefficient['Diametre'].coefficientOptionId
    if(!epaisseurCal || !diametre)return;
   const result = (await OperationExceptionService.getOperationExceptionCodes(diametre,epaisseurCal)).data;
    return result;
  }
  public edit(): void {
    if(this.isDisabled)return;
    //this.text = table.findByPath(this.props.rowData, this.props.rowField.property)
    if (!this.isReadOnly()) {
      this.isBeingEdited = !this.isBeingEdited;
      if (this.isBeingEdited) {
        this.previous = this.rawData;
        this.$nextTick(() => {
          (<HTMLInputElement>this.$refs.input).select();
          // (<HTMLElement>this.$refs.input).style.width = `${this.width}px`;
        });
      } else {
        if (this.isCalorifugeageTyuauterie) {
          this.computeOperationPoints();
        }
        (<any>this.$parent).reload();
      }
    }
  }

  private hideKeyboard() {
    (<HTMLInputElement>document.activeElement).blur();
  }

  private onRawDataChange() {
    if(this.isDisabled)return;
    this.canBeEdited =
        this.props.rowData.operation.operationFamilyKey !== "viroles";
    if(!this.canBeEdited)
      this.rawData = 0;
  }
private computeOperationPoints(){
  if(this.isDisabled)return;
  CaloTyuauterieHelper.computeOperationPoints(this.currentOperation,this.props.rowData);

}
  private created() {
    if(this.isDisabled)return;
    if (this.isCalorifugeageAppareil && this.props.rowField.property === "quantity") {
      this.onRawDataChange();
      this.$watch("rawData", this.onRawDataChange, {deep: true});
    } else if (this.isCalorifugeageTyuauterie) {
      this.computeOperationPoints();
      this.$watch("selectedGroupCoefficient",this.onCurrentOperationCoefficientChange,{deep:true})
      this.$watch("currentOperation.isReadOnly",this.onCurrentOperationIsReafOnlyChange,{deep:true})
    }
    (<any>this.$parent).reload();
  }
private  onCurrentOperationIsReafOnlyChange(){
  if(this.isDisabled)return;
  if(this.currentOperation.isReadOnly){
      this.props.rowData.fieldKey = Guid.create().toString();
    }
}
  private mounted() {
    if(this.isDisabled)return;
    this.width = (<HTMLElement>this.$refs.rawField).offsetWidth;
  }

  /**
   * ######
   * Watchers
   * ######
   */
  private async onCurrentOperationCoefficientChange(){
    if(this.isDisabled)return;
    await this.$nextTick();

    if(this.props.rowData.isSelectedGroupCoefficientsProcessed)return;
    this.props.rowData.isSelectedGroupCoefficientsProcessed = true;

    const exceptionCodes = await this.getExceptionOperationCodes();

    // first validation step of diametre and epaisseur
    if(!exceptionCodes){
      return;
    }

    // reset all operation to initial state
    for(let i in this.props.rowData.operations){
      this.props.rowData.operations[i].isReadOnly = false;
    }
    // lock the exception operations and set their quantity to 0
    exceptionCodes.forEach(code=>{
      this.props.rowData.operations[code].isReadOnly = true;
      this.props.rowData.operations[code].quantity = 0;
      this.props.rowData.operations[code].totalPoints = 0;
    });
    //second validation step
    if(this.selectedGroupCoefficient['K2'].coefficientOptionId) {
      CaloTyuauterieHelper.computeOperationPoints(this.props.rowData.operations[this.props.rowField.operationKey], this.props.rowData);
    }
    (<any>this.$parent).reload();
  }
}
