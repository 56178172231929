















import { Component } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import Bop from "@/models/Bop";
import { BopModelEnum } from "@/utils/Enums";

@Component({
  components: {},
})
export default class BopDropDownField extends BaseField {
  public selectedText: string = "";
  public init: boolean = true;
  private isBeingEdited: Boolean = false;

  get displayData(): any {
    return table.findByPath(this.props.rowData, this.props.rowField.display);
  }

  get options() {
    return this.props.rowField.options;
  }

  get valueField(): any {
    return this.props.rowField.valueField;
  }

  get textField(): any {
    return this.props.rowField.textField;
  }

  get selectedValue(): string {
    let result = "";
    if (this.init && this.displayData) {
      result = this.displayData;
    } else {
      let arrResult = this.options.find(
        (d: any) => table.findByPath(d, this.valueField) === this.rawData
      );
      result =
        arrResult === undefined
          ? ""
          : table.findByPath(arrResult, this.textField);
    }
    return result;
  }

  private get isEditable(): Boolean {
    return !this.props.rowField.isNotEditable && this.canBeEdited;
  }

  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }

  private get isBopReadOnly(): boolean {
    return (
      Bop.readOnlyStatuses.includes(this.$store.state.bop.bopStatusKey) &&
      !this.canBeEditedAfterRealised
    );
  }

  private get isCalorifugeageTyuauterie(): boolean {
    return (
      this.$store.state.selectedBopModel &&
      this.$store.state.selectedBopModel.key ===
        BopModelEnum.CalorifugageTuyauterie
    );
  }

  public change(newValue): void {
    this.init = false;
    if (this.isEditable && this.isBeingEdited) {
      //remove isBeingEdited if dblclick
      this.isBeingEdited = false;
      (<any>this.$parent).reload();
      this.setDataContainer(newValue);
      if (this.isCalorifugeageTyuauterie) {
        this.props.rowData.isSelectedGroupCoefficientsProcessed = false;
      }
    }
  }

  /**
   * Hooks
   */
  public created() {
    // if(this.isCalorifugeageTyuauterie && !this.rawData && this.props.rowField.coefficient.key === 'Diametre'){
    //   const options = this.options.sort((a,b)=> a.key.value - b.key.value);
    //   this.rawData = options[0].key.id;
    // }
    this.setDataContainer(this.rawData);
  }

  private edit() {
    if (this.isEditable && !this.isBeingEdited) {
      this.isBeingEdited = true;
    }
  }

  private setDataContainer(value) {
    if (!value || this.isBopReadOnly) return;

    const opt = this.options.find((o: any) => o.key.id == value);
    table.setByPath(
      this.props.rowData,
      this.props.rowField.dataContainer,
      opt.key
    );
  }
}
