

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import { Guid } from "guid-typescript";
import Coefficient from "@/models/Coefficient";
import OperationService from "@/services/OperationService";
import BopModel from "@/models/BopModel";
import { BopModelEnum } from "@/utils/Enums";
import Constants from "@/utils/Constants";
import Bop from "@/models/Bop";
import OperationGroup from "@/models/OperationGroup";

const HAUTEUR_COEFFICIENT_KEY_PER_BOPMODEL = {
  [BopModelEnum.CalorifugageTuyauterie]: "K3",
  [BopModelEnum.TuyauterieMetalique]: "K5",
  [BopModelEnum.SerrurerieCharpentes]: "K1",
  [BopModelEnum.PeintureIndustrielle]: "K2PEINT",
  [BopModelEnum.Echafaudage]: "Hauteur",
  [BopModelEnum.CalorifugageAppareil]: "Hauteur",
  [BopModelEnum.GenieCivil]: "-",
  [BopModelEnum.NettoyageIndustrielle]: "-",
  [BopModelEnum.TuyauteriePlastique]: "-",
  [BopModelEnum.ElectriciteInstrumentation]: "hauteur",
};
const INITIALIZED_BOP_MODEL:Array<string> = [
    BopModelEnum.SerrurerieCharpentes,BopModelEnum.TuyauterieMetalique
]
const INITIALIZED_COEFFICIENTS:Array<string> = [
    'K5',
    'K1',
    'K6', // soudure
    'K4', //pression nominal
    'K3', // epaisseur
    'K2' //coeff acier

]
const DISABLING_DIAMETRE_OPERATION_FAMILIES = [
  "nombreDeDecoupes",
  "semiDemontables",
  "piecesDeForme",
  "demontables",
];
const DISABLING_LONGUEUR_OPERATION_FAMILIES = [
  "nombreDeDecoupes",
  "fondsBombesOuConiques",
  "semiDemontables",
  "piecesDeForme",
  "demontables",
];
@Component({
  components: {},
})
export default class BopCoefficientDropDownField extends BaseField {
  private isBeingEdited: Boolean = false;
  public selectedText: string = "";
  public init: boolean = true;
  private options: Array<any> | null = null;

  // private get initOptions() {
  //   return this.props.rowField.options;
  // }
  private get operationGroupId(){
    return this.props.rowField.operationGroupId;
  }
  private get currentOperationPrincipalGroup():OperationGroup{
    return this.$store.state.bop.operationPrincipalGroups.find(og => og.id === this.operationGroupId);
  }
  private get coefficient(): Coefficient {
    return this.props.rowField.coefficient;
  }
  private get coefficientId() {
    return this.coefficient.id;
  }
  private get coefficientKey() {
    return this.coefficient.key;
  }
  private get selectedBopModel(): BopModel {
    return this.$store.state.selectedBopModel;
  }
  private get isBopTuyauteriePlastique():boolean{
    return this.selectedBopModel.key === BopModelEnum.TuyauteriePlastique;
  }
  private get selectedValue(): string {
    if (!this.options) return "";
    if (this.options.length) {
      let arrResult = this.options.find((d: any) => d.id === this.rawData);
      return arrResult === undefined ? "" : arrResult.name;
    }
    return " ";
  }
  private get selectedOperationId() {
    return this.props.rowData.operation.id;
  }
  private get isEditable() {
    return (
      this.options &&
      this.options.length > 1 &&
      !this.props.rowField.isNotEditable
    );
  }
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get isBopReadOnly(): boolean {
    const bop: Bop = this.$store.state.bop;
    return (
      Bop.readOnlyStatuses.includes(bop.bopStatusKey) &&
      !this.canBeEditedAfterRealised
    );
  }
  private get operationFamilyKey() {
    return this.props.rowData.operation.operationFamilyKey;
  }
  get isCalorifugeageAppareil(): boolean {
    return (
      this.$store.state.selectedBopModel.key ===
      BopModelEnum.CalorifugageAppareil
    );
  }
  /**
   * Methods
   */

  /***
   * Method used only for calo app
   * @private
   */
  private onOperationFamilyKeyChange() {
    // console.log("Im fired");
    // if(this.selectedBopModel.key !== BopModelEnum.CalorifugageAppareil)return;

    if (this.rawData === Constants.DEFAULT_VALUE_EMPTY_OPTIONS) {
      this.canBeEdited = false;
      return;
    }
    if (this.coefficientKey) {
      if (
        this.coefficientKey === "diametre" &&
        DISABLING_DIAMETRE_OPERATION_FAMILIES.includes(this.operationFamilyKey)
      ) {
        this.canBeEdited = false;
        this.props.rowData = Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
      } else if (
        this.coefficientKey === "longueur" &&
        DISABLING_LONGUEUR_OPERATION_FAMILIES.includes(this.operationFamilyKey)
      ) {
        this.rawData = Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
        this.canBeEdited = false;
      } else {
        this.canBeEdited = true;
      }
    }
  }
  public edit(eventType: string): void {
    this.init = false;
    // console.log("changing");
    if (!this.isEditable || !this.canBeEdited) {
      return;
    }
    if (eventType === "click") {
      this.isBeingEdited = true;
    } else if (eventType === "change") {
      this.isBeingEdited = false;
      (<any>this.$parent).reload();
    }
  }
  private async loadOptions(selectedOperationId) {
    const options: Array<any> = (
      await CoefficientOptionService.GetPossibleCoefficientOptions(
        this.coefficientId,
        selectedOperationId,
        this.$i18n.locale
      )).data;

    if (!this.isBopReadOnly) {
      if (options.length) {
        if(!this.rawData && this.selectedBopModel && INITIALIZED_BOP_MODEL.includes(this.selectedBopModel.key)
        && INITIALIZED_COEFFICIENTS.includes(this.coefficientKey)){
          const sortedOptions = options.sort((a,b)=> a.value - b.value);
          this.rawData = sortedOptions[0].id;
        }
        (<any>this.$parent).reload();
      } else if (!options.length) {
        this.rawData = Constants.DEFAULT_VALUE_EMPTY_OPTIONS;
      }
    }
    this.options = options;
  }
  /**
   * Hooks
   */
  public async created() {

    if (
      this.selectedOperationId &&
      this.selectedOperationId.length &&
      this.selectedOperationId !== Guid.createEmpty().toString()
    ) {

      await this.loadOptions(this.selectedOperationId);

    }
    if (this.rawData && this.selectedOperationId && !this.isBopReadOnly) {
      if (this.coefficient && this.coefficient.definesOperationUnitPoint) {
        const operatinUnitValue = (
          await OperationService.getOperationUnitPoint(
            this.selectedOperationId,
            this.rawData
          )
        ).data;
        this.props.rowData.operation.operationUnitValue = operatinUnitValue;
      }
    }
     if (this.isCalorifugeageAppareil) {
      this.onOperationFamilyKeyChange();
      this.$watch("operationFamilyKey", this.onOperationFamilyKeyChange);
    }
    // console.groupEnd();
  }
  /**
   * Watchers
   */
  @Watch("selectedValue")
  private async onRawDataChange() {
    if (!this.rawData || !this.options || this.isBopReadOnly) {
      return;
    }
    // in case a coefficient have no options so we have to set the value to 1
    if (this.rawData === Constants.DEFAULT_VALUE_EMPTY_OPTIONS)
    {
      const oldOpt = this.props.rowData.coefficientSelectedValues[
        this.coefficientKey
      ];
      const opt = {
        coefficientId: this.coefficientId,
        coefficientOptionId: null,
        id: oldOpt ? oldOpt.id : Guid.createEmpty().toString(),
        value: 1,
      };
      if (!this.props.rowData.coefficients)
        this.props.rowData.coefficients = {};
      this.props.rowData.coefficients[this.coefficientKey] = opt;
    } else {
      const opt = this.options.find((o: any) => o.id == this.rawData);
      table.setByPath(
        this.props.rowData,
        this.props.rowField.dataContainer,
        opt
      );
      // if the coefficient defines the operation unit point
      if (
        this.coefficient.definesOperationUnitPoint &&
        this.selectedOperationId
      ) {
        const operatinUnitValue = (
          await OperationService.getOperationUnitPoint(
            this.selectedOperationId,
            this.rawData
          )
        ).data;
        this.props.rowData.operation.operationUnitValue = operatinUnitValue;
      }
    }
  }
  @Watch("selectedOperationId")
  private async onSelectedOperationChange(oldOperationId, newOperationId) {
    if(oldOperationId === newOperationId || (newOperationId && this.currentOperationPrincipalGroup.operations.findIndex(o=> o.id === oldOperationId) === -1)) return;
    if(!this.isBopTuyauteriePlastique) {
      this.rawData = null;
      if (!this.props.rowData.coefficients) this.props.rowData.coefficients = {};
      this.props.rowData.coefficients[this.coefficientKey] = {};
    }
    await this.loadOptions(this.selectedOperationId);

  }
}
