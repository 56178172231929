















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import { BopModelEnum } from "@/utils/Enums";
import Bop from "@/models/Bop";

@Component({
  components: {},
})
export default class BopOperationDropDown extends BaseField {
  private isBeingEdited: Boolean = false;
  public selectedText: string = "";
  public init: boolean = true;
  get displayData(): any {
    return table.findByPath(this.props.rowData, this.props.rowField.display);
  }
  get operationGroupId() {
    return this.props.rowField.operationGroupId;
  }
  get isLocalOptions(): boolean {
    return this.localOptions ? true : false;
  }
  get localOptions(): boolean {
    return this.props.rowData.localOptions;
  }
  get options(): Array<any> {
    return this.isLocalOptions
      ? this.localOptions
      : this.$store.state.bopOperationOptions[this.operationGroupId] ?? [];
  }
  get valueField(): any {
    return this.props.rowField.valueField;
  }
  get isCalorifugeageAppareil(): boolean {
    return (
      this.$store.state.selectedBopModel.key ===
      BopModelEnum.CalorifugageAppareil
    );
  }
  private get isBopTuyauteriePlastique():boolean{
    return this.$store.state.selectedBopModel.key === BopModelEnum.TuyauteriePlastique;
  }

  get textField(): any {
    return this.props.rowField.textField;
  }
  get selectedValue(): string {
    let result = "";
    if (this.init && this.displayData) {
      result = this.displayData;
    } else {
      let arrResult = this.options.find(
        (d: any) => table.findByPath(d, this.valueField) === this.rawData
      );
      result =
        arrResult === undefined
          ? ""
          : table.findByPath(arrResult, this.textField);
    }
    return result;
  }
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get isBopReadOnly(): boolean {
    const bop: Bop = this.$store.state.bop;
    return (
      Bop.readOnlyStatuses.includes(bop.bopStatusKey) &&
      !this.canBeEditedAfterRealised
    );
  }
  public edit(eventType): void {
    this.init = false;
    if (eventType === "change") {
      this.isBeingEdited = !this.isBeingEdited;
    }
    if (!this.props.rowField.isNotEditable && !this.isBeingEdited) {
      //remove isBeingEdited if dblclick
      if (eventType === "click") {
        this.isBeingEdited = !this.isBeingEdited;
      }
      if (!this.isBeingEdited) {
        (<any>this.$parent).reload();
      }
    }
  }
  public created() {
    if (this.rawData && this.options.length && !this.isBopReadOnly) {
      const opt = this.options.find((o: any) => o.key.id == this.rawData);
      if(!opt){
        this.props.rowData.operation = {
          operationMesureUnitValu: 'U',
          
         };
         this.props.rowData.quantity= 0
        return;
      }
      table.setByPath(
        this.props.rowData,
        this.props.rowField.dataContainer,
        JSON.parse(JSON.stringify(opt.key))
      );
      if (this.isCalorifugeageAppareil) {
        const operationFunctions = {
          id: this.rawData,
          functionOperationUnitPoints:
            opt.key.operationFamilyFunctionOperationUnitPoints,
          functionUnitPoints: opt.key.operationFamilyFunctionUnitPoints,
        };

        this.$store.commit("changeOperationFuctions", operationFunctions);
      }
    }
  }
  @Watch("rawData")
  private onRawDataChange() {
    // console.log(this.rawData);
    if (this.rawData && !this.isBopReadOnly) {
      const opt = this.options.find((o: any) => o.key.id == this.rawData);
      table.setByPath(
        this.props.rowData,
        this.props.rowField.dataContainer,
        JSON.parse(JSON.stringify(opt.key))
      );
      if (this.isCalorifugeageAppareil) {
        const operationFunctions = {
          id: this.rawData,
          functionOperationUnitPoints:
            opt.key.operationFamilyFunctionOperationUnitPoints,
          functionUnitPoints: opt.key.operationFamilyFunctionUnitPoints,
        };
        this.$store.commit("changeOperationFuctions", operationFunctions);
      }
      if(!this.isBopTuyauteriePlastique) {
        this.props.rowData.coefficients = {}
      }
      this.props.rowData.totalPoints = 0
    }
  }

  @Watch("options")
  private onOptionChanged(oldOptions, newOptions) {
    if (!this.rawData || oldOptions === newOptions || this.isBopReadOnly) return;
    if (this.options.findIndex((o) => o.key.id === this.rawData) === -1) {
      // console.log("Im changed And Im not available");
      this.rawData = null;
      this.props.rowData.operation = {
          operationMesureUnitValu: 'U',
          
         };
      this.props.rowData.quantity= 0
      // this.props.rowData.operationUnitPoints = 0;
      // this.props.rowData.operation = {};
    }
  }
}
